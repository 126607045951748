import styles from './styles.module.scss';

export function MultiContentRow({
  content,
}: {
  content: { name: string; url: string }[];
}) {
  const isRow = content.length > 1;
  const isVideo = content[0].url.endsWith('.mp4');

  return (
    <>
      {isRow ? (
        <div className={styles.multi}>
          {content.map((image) => (
            <img key={image.name} className={styles.item} src={image.url} />
          ))}
        </div>
      ) : (
        <div className={styles.single}>
          {isVideo ? (
            <video muted autoPlay className={styles.item} controls>
              <source src={content[0].url} type="video/mp4" />
            </video>
          ) : (
            <img className={styles.item} src={content[0].url} />
          )}
        </div>
      )}
    </>
  );
}
